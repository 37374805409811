.download {
  position: relative;
  //   right: 10px;
  float: right;
}
.downloadIcon {
  height: 35px;
}
.pdfWrap {
  max-height: 90vh;
  overflow: scroll;
}
/* Hide scrollbar for Chrome, Safari and Opera */
.pdfWrap::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.pdfWrap {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
// .modal-header {
//   padding: 2rem 1rem !important;
// }


.Property-amenties-lable-container{
   display: flex;
   justify-content: center !important;
   align-items: center !important;
   width: 100%;
   height: 100%;
}