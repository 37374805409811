@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Syne:wght@400;500;600;700;800&display=swap');

body {
  // font-family: "Roboto", sans-serif !important;
  font-family: 'Outfit', sans-serif !important;
  background-color: #E5E5E5 !important;
}

.w-40{
  width: 40% !important;
}
.w-30{
  width: 30% !important;
}
.w-20{
  width: 20% !important;
}
.w-80{
  width: 80% !important;
}

// font size & weight
.f-10 {
  font-size: 10px !important;
}
.f-12 {
  font-size: 12px !important;
}
.f-14 {
  font-size: 14px !important;
}
.f-16 {
  font-size: 16px !important;
}
.f-18 {
  font-size: 18px !important;
}
.f-20 {
  font-size: 20px !important;
}
.f-24{
  font-size: 20px !important;
}
.fs-i {
  font-style: italic;
}
.fw-400 {
  font-weight: 400 !important;
}
.fw-500 {
  font-weight: 500 !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-700 {
  font-weight: 700 !important;
}
.fw-800 {
  font-weight: 800;
}
.fw-900 {
  font-weight: 900;
}
// font size & weight

$theme-colors: (
  "primary": #034168,
  "info": tomato,
  "danger": teal,
  "blue": #034168,
  "black": #000000,
  "gray1": rgba(21, 25, 32, 0.5),
  "color-primary": #015169,
  "color-02": #433A38,
  "color-03": #000000,
  "color-04": #636363,
  "color-05": #9E9D9B,
  "color-06": #C4A946,
  "color-07": #0398A6,
  "color-08": #E2E2E2,
  "color-09": #3B3935,
  "color-10": #FFFFFF,

);

/* import bootstrap to set changes */
@import "~bootstrap/scss/bootstrap";

