.filter-container {
  display: flex;
  gap: 20px;
  justify-content: space-between;
  margin-bottom: 20px;
  align-items: center;
}

.filter-container .form-select {
  width: 20%;
}

.propertyfilter {
  .multi-select-content {
    padding-top: 0 !important;
  }
  .react-datepicker-wrapper {
    width: auto;
  }
  input {
    width: 100%;
    padding: 7px;
    border: 1px solid #ced4da;
    border-radius: 0.375rem;
    margin-bottom: 3px;

    ::placeholder {
      color: #000 !important;
    }
  }
  
}

.propertyDemandTab {
  border: 3px solid #014559;
  cursor: pointer;
  .active {
    background-color: #014559;
    color: white;
    transform: scaleY(1.1);
  }

  div {
    padding: 5px;
    text-align: center;
    
  }

  .firstDiv {
    clip-path: polygon(0 0, 100% 0, 90% 100%, 0% 100%);
  }
  .secondDiv {
    clip-path: polygon(10% 0, 100% 0, 100% 100%, 0% 100%);
  }

}

//demandcard

.demand-card-container {
  .title-text {
    margin: 0px 1rem;
  }
}

// detail card
@media (max-width: 900px) {
  .detail-card {
    .mobileWrapper {
      word-break: break-all;
      width: 50% !important;
    }
  }
}
@media (max-width: 1000px) {
  .demand-card-container {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}
@media (max-width: 700px) {
  .demand-card-container {
    grid-template-columns: repeat(1, 1fr) !important;
  }
}
@media (max-width: 400px) {
  .demand-card-container {
    grid-template-columns: repeat(1, 1fr) !important;
    .demand-title {
      flex-direction: column !important;
    }
    .title-text {
      margin: 1rem 0px 0px 0px !important;
    }
  }
}

@media (max-width: 550px) {
  .detail-card {
    .row {
      display: block !important;
    }
    .detail-card-text {
      width: 100% !important;
    }
  }
}

.detail-card {
  .detail-card-text {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 40%;
  }
}

//brandpage styles
.brandPageForm-container {
  background: #ffffff;
  padding: 20px;
}
.brandPage-container {
  box-shadow: 0px 0px 7px rgb(0 0 0 / 17%);
  background: #ffffff;

  .card-container {
    gap: 20px;
    margin-bottom: 35px;
  }

  .brand-image {
    position: relative;
    height: 220px;
    width: 100%;
    background: linear-gradient(
      -140.59deg,
      #7fa2b8 33.13%,
      rgba(127, 162, 184, 0.46) 91.75%
    );
  }

  .brand-profile {
    width: 50%;
  }
  .brandPage-content {
    padding: 24px;
    border-radius: 8px;
  }
  .brand-profile-title {
    color: #ffffff;
  }

  .profile-container {
    position: relative;
    margin-bottom: 35px;
  }

  .brand-profile {
    width: 130px;
    height: 130px;
    object-fit: cover;
    padding: 6px;
    position: absolute;
    top: -58px;
    border-radius: 50%;
    background-color: #ffffff;
  }
  .profile-content {
    padding: 0px 0px 0px 150px;
  }
}
.detail-card {
  width: 100%;
  padding: 20px 24px 7px 24px;
  border-radius: 10px;
  background: #ebf0f4;
  margin-bottom: 35px;
}
@media (max-width: 900px) {
  .detail-card {
    .mobileWrapper {
      word-break: break-all;
      width: 50% !important;
    }
  }
  .brandPage-container .profile-content {
    padding: 150px 0px 0px 0px;
  }
}

//demand styles
.demand-card-container {
  grid-template-columns: repeat(3, 1fr);
  display: grid;
  gap: 20px;
  .demand-card {
    box-shadow: 0px 0px 7px rgb(0 0 0 / 17%);
    border-radius: 8px;
  }
  .demand-profile {
    width: 90px;
    height: 83px;
  }
  .responses {
    color: #3fa674;
    display: block;
    text-align: center;
    line-height: 21px;
    .responses-count {
      font-size: 32px;
      font-weight: 700;
    }
    .response-text {
      font-size: 12px;
      font-weight: 500;
    }
  }
}
.city-card-container {
  grid-template-columns: repeat(5, 1fr);
  display: grid;
  gap: 40px;
  margin-bottom: 35px;
  .city-card {
    box-shadow: 0px 0px 7px rgb(0 0 0 / 17%);
    border-radius: 8px;
    padding: 10px;
  }
  .city_image {
    width: 130px;
  }
}

// brand page style ends
.report-card-container {
  grid-template-columns: repeat(4, 1fr);
  display: grid;
  gap: 40px;
  margin-bottom: 35px;
  .report-card {
    box-shadow: 0px 0px 7px rgb(0 0 0 / 17%);
    background: #ebf0f4;
    border-radius: 10px;
    padding: 10px 10px 10px 25px;
  }
}

//-------------------------------------------------------------------------------------------

//developer page styles
.property-card-container {
  .responses {
    color: #3fa674;
    display: block;
    text-align: center;
    line-height: 21px;
    .responses-count {
      font-size: 32px;
      font-weight: 700;
    }
    .response-text {
      font-size: 12px;
      font-weight: 500;
    }
  }
}

// .property-profile{
//     width: 200px;
//     // height: 160px;
// }
.property-detail {
  width: 48%;
}
.property-card {
  box-shadow: 0px 0px 7px rgb(0 0 0 / 17%);
  border-radius: 8px;
}
@media screen and (max-width: 700px) {
  .filter-container {
    display: flex;
    justify-content: center;
  }
  .filter-container .form-select {
    margin-top: 20px;
    width: 100%;
  }
}

.btn-container {
  display: flex;
  gap: 20px;
  justify-content: flex-end;
}
@media (max-width: 900px) {
  .mobilePosition {
    margin-bottom: 10px;
  }
  .btn-container {
    margin-top: 10px;
  }
}

.operaWrapper {
  display: flex;
  .operaWrapper-para {
    width: 20%;
    height: 75px;
    margin: 10px;
    border-radius: 10px;
    background-color: #7fa2b83d;
    padding: 5px 25px;
    display: grid;
    b {
      font-weight: 600;
      font-size: 20px;
    }
  }
}

.wrapAgent {
  display: flex;
}
.imgnameWrap {
  width: 75%;
}
.totalPropertyWrapper {
  display: flex;
  flex-direction: column;
  max-height: 80px;
  // width: 25%;
  margin-left: 10px;

  p {
    display: flex;
    flex-direction: column;
    width: fit-content;
    background-color: #ebf0f4;
    padding: 10px;
    border-radius: 5px;
    align-self: center;
  }
}
// .totalPropertyWrapper {
//   display: flex;
//   flex-direction: column;
//   max-height: 80px;
//   width: 25%;

//   p {
//     display: flex;
//     flex-direction: column;
//     width: fit-content;
//     background-color: #ebf0f4;
//     padding: 10px;
//     border-radius: 5px;
//     align-self: center;
//   }
// }
.noDataWrapper{
  margin-left: 1.5rem;
}