:root {
  --topspace: 70px;
  --offset: 280px;
  --pointer: pointer;
  --primary: #015169;
  --sideBar-text: #c4c4c4;
  --primary-text: #c4c4c4;
  --white: #ffffff;
  --border-radius-default: 4px;
  --primary-active-color: #015169;
  /* //-------backGround-Color----------// */
  --rightside-content-bg: #E5E5E5;
  --table-content-bg: #ffffff;
  --input-bg: #ffffff;
  --select-bg: rgba(123, 151, 206, 0.68);
  --dashBoard-card-bg: rgba(127, 162, 184, 0.24);
  --white-background: #ffffff;
  --gray-background: #eaeaea;
  --checkbox-background: #034168;
  /* //-------backGround-Color end----------// */

  /* /------------------------border-color start----------------------------/ */
  --input-border-color: #818384;
  --input-palceHolder-color: #818384;
  --dashBoard-card-bg: rgba(127, 162, 184, 0.24);

  --border-color-red: #dd2025;
  /* //-------backGround-Color end----------// */

  /* /------------------------border-color start----------------------------/ */
  --input-border-color: #818384;
  /* /------------------------border-color end----------------------------/ */

  /* //-------text-Color----------// */
  --page-title-color: #015169;
  --white-text: #ffffff;
  --black-text: #333333;
  --dark-link-text: #6451bd;
  --gray-text: #676767;
  --dark-shadow-text: #3e4958;
  --red-text: #dd2025;

  /* //------text-Color end----------// */

  /* --------------------------progress-color start---------------------------- */
  --progress-active-bg: #034168;
  --progress-sucess-bg: #20c374;
  --progress-inactive-bg: #e5e5e5;
  --progress-white-bg: #ffffff;
  /* --------------------------progress-color end---------------------------- */

  /* /--------------------------------border color start--------------------*/
  --border-blue: #034168;
  /* /--------------------------------border color start--------------------*/

  --box-shadow-button: 0px 4px 15px rgba(0, 0, 0, 0.15);
}

$black-color: #000000;

//common styles
.cursor-pointer {
  cursor: pointer;
}

.overflow-hidden-ellipsis {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
}

//scroll styles
.customScroll::-webkit-scrollbar {
  width: 4px;
  height: 5px;
}

.customScroll::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 20px;
}

.customScroll::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 20px;
}

.customScroll::-webkit-scrollbar-thumb:hover {
  background: #555;
}

//  Text style-------------------------------------------------starts



.text-heading {
  font-size: 24px;
  color: $black-color;
  font-weight: 500;
  letter-spacing: 0.005em;
  margin-bottom: 25px;
  font-family: 'Syne', sans-serif !important;
}

// text style---------------------------------------------------ends

// card styles
//small card
.small-card {
  background: #EBF0F4;
  border-radius: 10px;
  width: 214px;
  padding: 10px 10px 10px 10px;
}

.card-text-heading {
  font-size: 20px;
  color: #000000;
  font-weight: 500;
  letter-spacing: 0.005em;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.body-text-heading {
  font-size: 24px;
  color: #000000;
  font-weight: 500;
  letter-spacing: 0.005em;
  font-family: 'Outfit', sans-serif !important;
}

.card-sub-text {
  font-size: 16px;
  line-height: 136%;
  color: #000000;
  font-weight: 400;
}

// form-switch and form-check styles-----------------------
.form-check .form-check-input {
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.form-check-input {
  border-color: var(--checkbox-background) !important;
  margin-right: 0.25em !important;
}

.form-check-input:focus {
  outline: none !important;
  box-shadow: none !important;
}

.form-check-input:checked {
  background-color: var(--checkbox-background) !important;
}

.form-switch .form-check-input {
  cursor: pointer;
  border-color: #E7EAEB;
  background-color: #E7EAEB !important;
  border: none;
}

.form-select:focus,
.form-control:focus {
  border-color: #034168 !important;
  box-shadow: none !important;
}

.form-group:focus-within label {
  color: #034168 !important;
}

.form-switch .form-check-input:checked {
  background-color: #015169 !important;
}

.form-switch .form-check-input:checked:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27%23fff%27/%3e%3c/svg%3e") !important;
}

.form-switch .form-check-input:not(:checked):focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%27-4 -4 8 8%27%3e%3ccircle r=%273%27 fill=%27rgba%280, 0, 0, 0.25%29%27/%3e%3c/svg%3e") !important;
}

.was-validated .form-control:invalid:focus,
.form-control.is-invalid:focus {
  border-color: #dc3545 !important;
}

.was-validated .form-select:invalid:focus,
.form-select.is-invalid:focus {
  border-color: #dc3545 !important;
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
  background-image: none !important;
}

.was-validated .form-control:invalid,
.form-select.is-invalid {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27m2 5 6 6 6-6%27/%3e%3c/svg%3e") !important;
}

.form-control.is-valid,
.was-validated .form-control:valid {
  background-image: none !important;
}

.form-control.is-invalid,
.was-validated .form-control:invalid {
  background-image: none !important;
}

.was-validated .form-select:invalid:not([multiple]):not([size]) {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e") !important;
  // padding: 5px !important;
}

.was-validated .form-select:valid:not([multiple]):not([size]) {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27%3e%3cpath fill=%27none%27 stroke=%27%23343a40%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%272%27 d=%27M2 5l6 6 6-6%27/%3e%3c/svg%3e") !important;
  // padding: 5px !important;

}

// cancel button style 

.btn-outline-color-primary {
  background-color: white !important;
  color: rgba(21, 25, 32, 0.5) !important;
  border: 1px solid #015169 !important;
  border-radius: 8px !important;

}

.error-msg {
  font-size: 11px;
  color: #dc3545;
}

.form-check .form-check-input {
  width: 1.5rem !important;
  height: 1.5rem !important;
}

.form-check-input {
  border-color: var(--checkbox-background) !important;
}

.form-check-input:focus {
  outline: none !important;
  box-shadow: none !important;
}



.form-switch .form-check-input {
  cursor: pointer;
  border-color: #E7EAEB;
  background-color: #E7EAEB !important;
  border: none;
}

.form-switch .form-check-input:checked {
  background-color: #034168 !important;
}

.check-input:valid~.form-check-label {
  color: #000000 !important;
}


.form-check-input:checked[type=checkbox] {
  background-color: #034168 !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.checkbox-multiselect {
  margin-top: 28px;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  padding: 0 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.btn-color-primary,
.btn-primary {
  background-color: #015169 !important;
  color: #fff !important;
}

.btn-second {
  background-color: #fff !important;
  color: #000 !important;
  border-color: #56678942 !important;
}

.cancel-btn {
  background-color: white !important;
  color: rgba(21, 25, 32, 0.5) !important;
  border: 1px solid rgba(86, 103, 137, 0.26) !important;
  border-radius: 8px !important;
  font-weight: 600 !important;
  padding: 10px 40px !important;

  &:hover {
    background-color: white !important;
    color: rgba(21, 25, 32, 0.5) !important;
  }
}

.submit-btn {
  background: #034168 !important;
  color: white !important;
  border-radius: 8px !important;
  font-weight: 600 !important;
  border: 1px solid #034168 !important;
  padding: 10px 40px !important;

  &:hover {
    color: #FFFFFF !important;
    background: #034168 !important;
  }
}

.pre-btn {
  display: block;
  width: 120px;
  height: 40px;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  color: rgba(21, 25, 32, 0.5) !important;
  border-radius: 6px !important;
  font-weight: 600 !important;
  border: 1px solid rgba(86, 103, 137, 0.26) !important;
  background: #FFFFFF !important;

  &:hover {
    color: rgba(21, 25, 32, 0.5) !important;
    background: #FFFFFF !important;
  }

}

.next-btn {
  display: block;
  width: 120px;
  height: 40px;
  // display: flex;
  // align-items: center;
  // justify-content: center;
  border-radius: 6px !important;
  font-weight: 600 !important;

  background: #015169 !important;
  color: white !important;

  &:hover {
    color: #FFFFFF !important;
    background: #015169 !important;
  }

}

.error-message_span {
  color: #dc3545;
  font-size: 12px;
}

.syne-font {
  font-family: 'Syne', sans-serif !important;
}




.user-cards::-webkit-scrollbar {
  width: 4px;
  height: 5px;
}

.user-cards::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 20px;
}

.user-cards::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 20px;
}

.user-cards::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.word-wrap {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}

.edit-table-button-right,
.edit-table-button-left {
  background: white !important;
  color: #000 !important;
  margin-right: 20px !important;
  padding: 2px !important;
}

.edit-table-button-left {
  margin-left: 20px !important;

}

.swal-icon--success {
  border-color: #015169 !important;
  animation: none !important;
}

.swal-icon--success__line,
.swal2-x-mark-line-left,
.swal2-x-mark-line-right,
.swal-icon--error__line,
.swal-icon--error__line--left {
  background-color: #015169 !important;
}

.swal2-success-ring {
  display: none !important;
}

.swal-icon--success__ring {
  display: none !important;
}

.swal-modal {
  animation: none !important;
}

.swal-icon--success:after {
  // transform: none !important;
  animation: none !important;
}

.swal-icon--error {
  border-color: #015169 !important;
  animation: none !important;
  border: none;
}

.swal-icon--error__x-mark {
  animation: none !important;
}

.swal-icon--warning {
  border-color: #015169 !important;
  animation: none !important;
}

.swal-icon--warning__body,
.swal-icon--warning__dot {
  background-color: #015169 !important;
}

.swal-icon--success__line--long,
.swal-icon--success__line--tip {
  animation: none !important;
}

.pg-viewer-wrapper {
  overflow-y: hidden !important;
}

// 
.swal2-icon.swal2-warning {
  border-color: #015169 !important;
  color: #015169 !important;
}

.swal2-icon.swal2-warning.swal2-icon-show {
  animation: none !important;
}

.swal2-icon.swal2-success.swal2-icon-show .swal2-success-line-tip {
  animation: none !important;
}

.swal2-icon.swal2-success.swal2-icon-show .swal2-success-line-long {
  animation: none !important;
}

.swal2-success-line-long,
.swal2-success-line-tip {
  border-color: #015169 !important;
  color: #015169 !important;
}

.swal2-icon.swal2-success .swal2-success-ring {
  border: none !important;
  display: none !important;
}

.swal2-icon.swal2-success.swal2-icon-show .swal2-success-circular-line-right {
  display: none !important;
}

.swal2-icon.swal2-success {
  // border-color: #015169 !important;
  border: none !important;
  color: #015169 !important;
  // display: none !important;
}

.swal2-icon.swal2-success [class^=swal2-success-line] {
  background-color: #015169 !important
}

.swal2-close {
  border: none !important;
}

.swal2-close:hover {
  color: #015169 !important;
}

.swal2-icon.swal2-error {
  border: none !important;
}

.propertySelect {
  .selectCustom {
    width: 100% !important;
  }
}

[class^=swal2] {
  -webkit-tap-highlight-color: none !important;
}

.swal2-close {
  border: none !important;
}

.swal2-close:hover {
  color: #015169 !important;
}

.swal2-popup .swal2-close:focus {
  box-shadow: none !important;
}

.white-space {
  white-space: nowrap;
}

.capitalize {
  text-transform: capitalize;
}

// notification dot
.notification-count {
  position: absolute;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  right: 0;
  top: 0;
  background-color: #dd2025;
}

.dropdown-content {
  max-height: 150px;
  /* Adjust as needed */
  overflow-y: auto;
}

.z-1000 {
  z-index: 1000;
}

.table-wrapper {
  overflow-y: scroll;
  border-width: 2px;
}

.table-wrapper::-webkit-scrollbar {
  display: none;
}


/* Custom CSS to replicate Tailwind functionality */
.custom-transform {
  transition: transform 0.3s ease;
}

.custom-transform:hover {
  transform: scale(1.02);
}

.cursor-pointer {
  cursor: pointer;
}
