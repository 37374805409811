.dashboard-container {
  .card-container {
    gap: 15px;
  }
  .dashboard-card-container {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
    gap: 20px;
  }
  .db-bg-img {
    background-color: #ebf0f4;
    height: 174px;
    margin-bottom: 20px;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
    img {
      height: 100%;
      width: 30%;
    }
    .db-bg-content {
      width: 60%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 10px;
      .db-welcomeMsg {
        font-family: "Syne";
        font-style: normal;
        font-weight: 600;
        font-size: 40px;
        line-height: 38px;
        color: #015169;
        margin-bottom: 10px;
      }
      .db-subtext {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 24px;
        color: #636363;
      }
    }
  }
}

.dashboard_Refresh {
  // position: absolute;
  // top: -60px;
  // right: 0;
  display: flex;
  align-items: end;
  justify-content: end;

  img {
    width: 20px;
  }

  span {
    display: block;
    color: #034168;
    font-weight: 700;
  }

  .custom_ref_btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    border: 2px solid #034168;
    padding: 5px 10px !important; 
}
}


.Relevancy_switch {
  position: absolute;
  top: -52px;
  right: 150px;

}

.Relevancy_Refresh {
  position: absolute;
  top: -60px;
  right: 0;

  img {
    width: 20px;
  }

  span {
    display: block;
    color: #034168;
    font-weight: 700;
  }

  .custom_ref_btn {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    border: 2px solid #034168;
    padding: 5px 10px !important; 
  }

  .custom_ref_btn:hover {
    
    background: #034168;
    span {
      color: #ebf0f4;
    }
    i {
      color: white;
    }
  }
}
.db-table-container {
  .db-heading {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: #000000;
  }
  .db-number-card-container {
    display: flex;
    justify-content: center;
    .db-number-card {
      width: 55px;
      height: 42px;
      background: #ffffff;
      border: 1px solid #e2e2e2;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      svg {
        color: #000000;
        width: 20px;
        height: 20px;
      }
    }
  }
  .db-filter-container {
    display: flex;
    justify-content: space-between;
    position: relative;
    margin-bottom: 20px;
    .filter-select {
      display: flex;
      width: 100%;
      // column-gap: 10px;
      .form-group {
        margin-bottom: 0;
      }
      .css-1qjd4bp-placeholder, .css-qc6sy-singleValue{
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 90%;
      }
    }
    .filter-search {
      display: flex;
      justify-content: end;
      width: 50%;
    }

    .filter-search-inbox {
      display: flex;
      justify-content: end;
      width: 50%;
    }
  }
}
.hyperlinkText{
  cursor: pointer;
  text-decoration: underline;
  color: #034168;
  font-weight: 500;
}

@media screen and (max-width: 1050px) {
  .dashboard-container {
    .dashboard-card-container {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

@media (max-width: 900px) {
  .dashboard-container{
    .db-bg-img {
      height: 150px;
      img {
        height: 100%;
        width: 40%;
      }
      .db-bg-content {
      
        .db-welcomeMsg {
          font-size: 25px;
        }
        .db-subtext {
          font-size: 17px;
        }
      }
    }
  }
 
}
@media (max-width: 800px) {
  .db-table-container{
   .db-filter-container{
    flex-direction: column;

  .filter-select{
    flex-direction: row;
    width: 100%;
    justify-content: center;
  }
  .filter-search{
    width: 100%;
    align-items: center;
    justify-content: center;
    .search-bar{
      width: 100%;
      
      margin-right: 0;
    }
  }
   }
  }
 
}

@media (max-width: 700px) {
  .small-card {
    width: 100% !important;
  }
  .dashboard-container{
    .db-bg-img {
    
      .db-bg-content {
      
        .db-welcomeMsg {
          font-size: 17px;
        }
        .db-subtext {
          font-size: 13px;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .dashboard-container{
    .db-bg-img {
      height: 100px;
      .db-bg-content {
      
        .db-welcomeMsg {
          font-size: 16px;
          line-height: 0px;
        }
        .db-subtext {
          font-size: 11px;
        }
      }
    }
  }
 
}